/**
 * Created by Joerg on 18.07.2017.
 */

import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'dialog-overview-example-dialog',
    template: `<h2 mat-dialog-title>Delete all</h2>
<mat-dialog-content>Are you sure?</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>No</button>
  <!-- Can optionally provide a result for the closing dialog. -->
  <button mat-button [mat-dialog-close]="true">Yes</button>
</mat-dialog-actions>
`,
})
export class DialogOverviewExampleDialog {}

@Component({
    selector: 'mn-material-test',
    templateUrl: './MnMaterialTest.html',
    styleUrls: ['./test.css']
})
export class MnMaterialTest {
    constructor(public dialog: MatDialog) {}
    openDialog() {
        this.dialog.open(DialogOverviewExampleDialog);
    }

}
